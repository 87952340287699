import dataLayer from "./index";

const forms = {
  "DS-5504": "e0dfa980-f697-5b56-8ca3-2c91479e954b",
  "DS-64": "559d8b00-8315-50b3-9db3-0e363b8d9506",
  "DS-11": "59ce1679-1249-5ead-8c10-c0ee9731cfe8",
  "DS-82": "8684cd82-3522-514e-a646-2e164bce07b2",
};

export const sendViewItemListEvent = (formNames, price) => {
  dataLayer.push("view_item_list", {
    ecommerce: {
      currency: "USD",
      view_item_list: {
        items: formNames.map((formName) => ({
          item_id: forms[formName],
          item_name: formName,
          item_category: "Form",
          price: price,
          quantity: 1,
        })),
      },
    },
  });
};

export const sendViewItemEvent = (formName, price) => {
  dataLayer.push("view_item", {
    ecommerce: {
      view_item: {
        currency: "USD",
        value: price,
        items: {
          item_id: forms[formName],
          item_name: formName,
          item_category: "Form",
          price: price,
          quantity: 1,
        },
      },
    },
  });
};

export const sendAddToCartEvent = (formName, addons, price, totalPrice) => {
  let cartItems =
    addons.length === 0
      ? []
      : addons.map((addon) => ({
          item_id: addon.id,
          item_name: addon.name,
          item_category: "Addon",
          price: addon.amount,
          quantity: 1,
        }));

  cartItems.unshift({
    item_id: forms[formName],
    item_name: formName,
    item_category: "Form",
    price: price,
    quantity: 1,
  });

  dataLayer.push("add_to_cart", {
    ecommerce: {
      add_to_cart: {
        currency: "USD",
        value: totalPrice,
        items: cartItems,
      },
    },
  });
};

export const sendRemoveFromCartEvent = (addon) => {
  dataLayer.push("remove_from_cart", {
    ecommerce: {
      remove_from_cart: {
        currency: "USD",
        value: addon.amount,
        items: [
          {
            item_id: addon.id,
            item_name: addon.name,
            item_category: "Addon",
            price: addon.amount,
            quantity: 1,
          },
        ],
      },
    },
  });
};

export const sendBeginCheckoutEvent = (formName, addons, price, totalPrice) => {
  let cartItems =
    addons.length === 0
      ? []
      : addons.map((addon) => ({
          item_id: addon.id,
          item_name: addon.name,
          item_category: "Addon",
          price: addon.amount,
          quantity: 1,
        }));

  cartItems.unshift({
    item_id: forms[formName],
    item_name: formName,
    item_category: "Form",
    price: price,
    quantity: 1,
  });

  dataLayer.push("begin_checkout", {
    ecommerce: {
      begin_checkout: {
        currency: "USD",
        value: totalPrice,
        items: cartItems,
      },
    },
  });
};

export const sendPurchaseEvent = (
  formName,
  orderReference,
  addons,
  price,
  totalPrice,
  formdata
) => {
  let cartItems =
    addons.length === 0
      ? []
      : addons.map((addon) => ({
          item_id: addon.id,
          item_name: addon.name,
          item_category: "Addon",
          price: addon.amount,
          quantity: 1,
        }));

  cartItems.unshift({
    item_id: forms[formName],
    item_name: formName,
    item_category: "Form",
    price: price,
    quantity: 1,
  });

  dataLayer.push("purchase", {
    ecommerce: {
      purchase: {
        transaction_id: orderReference,
        value: totalPrice,
        currency: "USD",
        emailAddress: formdata.emailAddress,
        firstName: formdata.firstName,
        lastName: formdata.lastName,
        street: formdata.address1,
        city: formdata.city,
        stateTerritory: formdata.stateTerritory,
        country: formdata.country,
        zipCode: formdata.zipCode,
        phoneNumber: formdata.phoneNumber1,
        items: cartItems,
      },
    },
  });
};
